angular.module("app")
    .controller("staffUploadController", ["$rootScope", "$scope", "$log", "$state", "$stateParams", "Notification", "FileUploader", "jobService", "$uibModal", "$timeout", "currentOrganisation", "departmentService", function ($rootScope, $scope, $log, $state, $stateParams, Notification, FileUploader, jobService, $uibModal, $timeout, currentOrganisation, departmentService) {

        var vm = this;
        vm.submitting = false;
        vm.pageNotReady = vm.loading = true;
        vm.batchJobs = [];
        vm.organisationReference = undefined;
        vm.uploader = undefined;
        vm.uploadProgress = 0;
        var FIFTEEN_SECONDS = 15 * 1000;
        var pollTimer;
        vm.pageNotReady = true;
        vm.loading = false;

        init();

        function init() {
            currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                vm.organisationReference = organisationReference;
                loadOrganisationJobs();
                poll();
            });

            vm.uploader = new FileUploader({
                method: 'POST',
                'Content-Type': 'undefined',
                'queueLimit': 1,
                'removeAfterUpload': true
            });

            vm.uploader.filters.push({
                name: 'csvOnly',
                fn: function (item) {
                    if (!vm.isCsvFile(item)) {
                        Notification.error("Incompatible file type", "Uploaded file must be in CSV format");
                        return false;
                    }
                    return true;
                }
            });

            vm.uploader.onCompleteItem = function (fileItem, response, status, headers) {
                vm.batchJobs.push(response);
                vm.uploadProgress = 100;
                vm.uploader.queue.file = undefined;
                Notification.success('File uploaded successfully.');
                vm.loading = false;
            };
            bindLoaderStateEvents();
        }

        vm.uploader.onBeforeUploadItem = function (item) {
            item.url = sprintf("/staff/%s/upload", vm.organisationReference);
        };

        vm.uploader.onAfterAddingFile = function (fileItem) {
            vm.uploadProgress = 0;
        };

        vm.uploader.onProgressItem = function () {
            vm.loading = true;
        };

        vm.getJob = function (jobReference) {
            jobService.getJobDetail(vm.organisationReference, jobReference).then(function (result) {
                vm.loading = true;
                showJobDetail(result);
            })
                ['finally'](function () {
                vm.loading = false;
                vm.pageNotReady = false;
            });

        };

        vm.isCsvFile = function (item) {
            var ext = item.name.slice((item.name.lastIndexOf(".") - 1 >>> 0) + 2);
            $log.debug('file ext ' + ext);
            return item.type == "text/csv" || ext == "csv";
        };

        vm.getCodes = function () {
            departmentService.get(vm.organisationReference)
                .then(function (result) {
                    vm.loading = true;
                    showCodes(result);
                })
                ['finally'](function () {
                vm.loading = false;
                vm.pageNotReady = false;
            });
        };

        function showCodes(result) {
            vm.organisation = result;
            return $uibModal.open({
                animation: true,
                templateUrl: '/static/templates/org-codes.html',
                scope: $scope
            });
        }

        function loadOrganisationJobs() {
            vm.loading = true;
            jobService.getJobs(vm.organisationReference).then(function (result) {
                vm.batchJobs = result;
                $log.debug('jobs %o', vm.batchJobs);
                vm.loading = false;
            });
        }

        function showJobDetail(result) {
            vm.jobDetail = result;
            return $uibModal.open({
                animation: true,
                templateUrl: '/static/templates/job-detail.html',
                scope: $scope,
                size: 'lg'
            });
        }

        function poll() {
            $log.debug("Starting poll");
            vm.loading = true;
            jobService.getJobs(vm.organisationReference).then(function (result) {
                vm.batchJobs = result;
            }).catch(function (error) {
                vm.serverError = error.data;
            })['finally'](function () {
                vm.loading = false;
                setPollTimeout();
            });
        }

        function setPollTimeout() {
            cancelPollTimeout();
            pollTimer = $timeout(poll, FIFTEEN_SECONDS);
        }

        function cancelPollTimeout() {
            if (pollTimer) $timeout.cancel(pollTimer);
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });

            $rootScope.$on('$locationChangeSuccess', function () {
                if ($state.current.url !== "/upload") {
                    cancelPollTimeout();
                }
            });
        }
    }]);